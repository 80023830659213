import { useParams, useSearch } from '@tanstack/react-router'
import { memo, useCallback, useReducer } from 'react'

import { css } from '@emotion/react'

import { checkDefinedAndNotNull } from '@orus.eu/error'
import {
  Button,
  PersistentNotification,
  Text,
  TextInputLabelWrapper,
  ValidatedTextField,
  spacing,
  useAsyncCallback,
  useScreenType,
  useTranslate,
} from '@orus.eu/pharaoh'
import { trpc } from '../../../client'
import { useNavigateBack } from '../../../lib/hooks/use-navigate-back'
import { GoBackButton } from './components/go-back-button'
import { SubscriptionStepLayout } from './components/subscription-step-layout'
import { subscriptionStepOuterElementCss } from './components/subscription-step-outer-element-style'
import { SubscriptionV2Header } from './components/subscription-v2-header'
import { LegacyDataPolicyAcceptanceCheckbox } from './elements/DataPolicyAcceptanceSubscriptionUiElementBlock'
import { mappers } from './elements/string-element-mappers'
import { SubscriptionPageV2 } from './subscription-v2-page'

const initialFormState = {
  email: '',
  phone: '',
  hasConsented: false,
  isSent: false,
}

type ReducerAction =
  | {
      type: 'phone_change' | 'email_change'
      value: string | undefined
    }
  | {
      type: 'set_consent'
      value: boolean | undefined
    }
  | {
      type: 'sent'
    }

const formReducer = (state: Partial<typeof initialFormState>, action: ReducerAction) => {
  switch (action.type) {
    case 'phone_change':
      return {
        ...state,
        phone: action.value,
      }
    case 'email_change':
      return {
        ...state,
        email: action.value,
      }
    case 'set_consent':
      return {
        ...state,
        hasConsented: action.value,
      }
    case 'sent':
      return {
        ...state,
        isSent: true,
      }
  }
}

const SubscriptionExclusionPageV2 = memo(function SubscriptionExclusionPageV2() {
  const translate = useTranslate()
  const navigateBack = useNavigateBack()
  const screenType = useScreenType()

  const { subscriptionId } = useParams({ from: '/subscribe-exclusion/$subscriptionId' })
  const searchParams = useSearch({ strict: false })
  const description = searchParams.description
  const stepId = searchParams.stepId
  const operatingZone = searchParams.operatingZone ?? 'fr'

  const [state, dispatch] = useReducer(formReducer, initialFormState)

  const emailChangeHandler = useCallback(
    (value: string | undefined) => dispatch({ type: 'email_change', value }),
    [dispatch],
  )

  const phoneChangeHandler = useCallback(
    (value: string | undefined) => dispatch({ type: 'phone_change', value }),
    [dispatch],
  )

  const consentChangeHandler = useCallback(
    (value: boolean | undefined) => dispatch({ type: 'set_consent', value }),
    [dispatch],
  )

  const onSubmit = useAsyncCallback(async () => {
    if (!state.phone || !state.email) return

    await trpc.subscriptions.updateContactInformationWithoutErrorManagement.mutate({
      subscriptionId,
      phone: state.phone,
      email: state.email,
    })
    dispatch({ type: 'sent' })
  }, [state, subscriptionId])

  const isReadyToSend = state.phone && state.email && state.hasConsented

  return (
    <SubscriptionPageV2>
      <div css={subscriptionStepOuterElementCss}>
        <SubscriptionV2Header progress={1} compact={false} />
        <SubscriptionStepLayout
          left={<GoBackButton goBackToPreviousStep={navigateBack} />}
          right={<></>}
          compact={false}
          center={
            <div>
              <Text variant="h5">{translate('exclusion_page_title')}</Text>
              <Text
                css={css`
                  margin-top: ${spacing[70]};
                `}
                variant="body2"
              >
                {description ? description : translate('exclusion_page_default_text')}
              </Text>
              <Text
                css={css`
                  margin-top: ${spacing[70]};
                  margin-bottom: ${spacing[70]};
                `}
                variant="body2"
              >
                {translate('exclusion_page_maybe_later')}
              </Text>

              {stepId !== 'post-quote-rcda-revenue-data' &&
                (!state.isSent ? (
                  <>
                    <TextInputLabelWrapper
                      css={css`
                        margin-top: ${spacing[70]};
                      `}
                      label={translate('dimension_name_email')}
                    >
                      <ValidatedTextField
                        size="large"
                        placeholder={translate('dimension_placeholder_email')}
                        mapper={mappers.email}
                        value={state.email}
                        onChange={emailChangeHandler}
                      />
                    </TextInputLabelWrapper>
                    <TextInputLabelWrapper
                      css={css`
                        margin-top: ${spacing[50]};
                        margin-bottom: ${spacing[70]};
                      `}
                      label={translate('dimension_name_phone')}
                    >
                      <ValidatedTextField
                        size="large"
                        placeholder={translate('dimension_placeholder_phone')}
                        mapper={mappers.phone}
                        value={state.phone}
                        onChange={phoneChangeHandler}
                      />
                    </TextInputLabelWrapper>
                    <LegacyDataPolicyAcceptanceCheckbox
                      type="selfonboarding"
                      hasAccepted={!!state.hasConsented}
                      operatingZone={operatingZone}
                      setHasAccepted={consentChangeHandler}
                    />
                    <div
                      css={css`
                        display: flex;
                        width: 100%;
                        justify-content: flex-end;
                      `}
                    >
                      <Button
                        type="submit"
                        css={css`
                          margin-top: ${spacing[70]};
                        `}
                        variant="primary"
                        size={screenType === 'desktop' ? 'medium' : 'large'}
                        onClick={onSubmit}
                        disabled={!isReadyToSend}
                        trackingId="contact_button"
                      >
                        {translate('send')}
                      </Button>
                    </div>
                  </>
                ) : (
                  <PersistentNotification variant="success" title={translate('exclusion_page_after_sending_title')}>
                    <Text variant="body2">
                      {translate('exclusion_page_after_sending_detail', {
                        email: checkDefinedAndNotNull(state.email),
                        phone: checkDefinedAndNotNull(state.phone),
                      })}
                    </Text>
                  </PersistentNotification>
                ))}
            </div>
          }
        />
      </div>
    </SubscriptionPageV2>
  )
})

export default SubscriptionExclusionPageV2
